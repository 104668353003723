/* global jsPDF */
import React from 'react';
import { Tabs, Select, Space ,Empty} from 'antd'; 
import { Table, Tag} from 'antd';
import SiteLayout from '@layouts/site-layout';
import axios from "axios"
import queryString from 'query-string';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf'



// const queryString = require('query-string');




const columns = [
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      render: text => <a>{text}</a>,
    //   responsive: ['md'],
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic', 
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade', 
    },
    {
        title: 'Time Per Question',
        dataIndex: 'tpq',
        key: 'tpq', 
      },
      {
        title: 'Percentage',
        dataIndex: 'percentage',
        key: 'percentage', 
      },



     
  ];
  
  // const data = [
  //   {
  //     key: '1',
  //     name: '1',
  //     age: 32,
  //     address: 'New York No. 1 Lake Park', 
  //   },
  //   {
  //     key: '2',
  //     name: '2',
  //     age: 42,
  //     address: 'London No. 1 Lake Park', 
  //   },
  //   {
  //     key: '3',
  //     name: '3',
  //     age: 32,
  //     address: 'Sidney No. 1 Lake Park', 
  //   },
  //   {
  //       key: '4',
  //       name: '4',
  //       age: 32,
  //       address: 'Sidney No. 1 Lake Park', 
  //     },
  //     {
  //       key: '5',
  //       name: '5',
  //       age: 32,
  //       address: 'Sidney No. 1 Lake Park', 
  //     },
  // ];



export default class InfoPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
     name:'',
     chapterName:'', 
     chapterScore: '',
     topics:[],
     imageWidth:false,
     empty:false,
     table:[],
     queries:false,
     
 }
}

  async componentDidMount(){
    const UrlQueryStrings = this.props.location.search;
    console.log(UrlQueryStrings);
    
    // const queryValues = queryString(UrlQueryStrings);
    let queryValues = queryString.parse(UrlQueryStrings)
    console.log(queryValues.chapter);
    let chapter = queryValues.chapter
    let chapterId = queryValues.chapterId
    let userId = queryValues.userId
    if(chapter === undefined) {
      let chapterUrl = `https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD/user-chapter-detail?userId=${userId}&chapterId=${chapterId}`;
      let apiCall = await axios.get(chapterUrl)
      console.log(apiCall.data['hits']['hits'].length);
      let apiData = apiCall.data['hits']['hits']
      if(apiCall.data['hits']['hits'].length === 0 ){
        this.setState({
          empty:true
        })
   
       } else {
        let name = ''
        if ((apiData[0]["_source"]["first_name"]!='' && apiData[0]["_source"]["first_name"]!=null) && (apiData[0]["_source"]["last_name"]!='' && apiData[0]["_source"]["last_name"]!=null)) {
          name = apiData[0]["_source"]["first_name"] + " " + apiData[0]["_source"]["last_name"];
      } else if (apiData[0]["_source"]["first_name"]!='' && apiData[0]["_source"]["first_name"]!=null) {
          name = apiData[0]["_source"]["first_name"];
      } else if (apiData[0]["_source"]["UserName"]!='' && apiData[0]["_source"]["UserName"]!=null) {
          name = apiData[0]["_source"]["UserName"];
      }
      // console.log(name);
      let chapter_name = apiData[0]["_source"]["chapter_name"]


       let chapter_score = ''
       let grade = ''
       let topic = ''
       let final = []
       apiData.map((result,k )=> {
        if (result["_source"]["test_type"]=="chapter"){
          // console.log(result["_source"]["chapter_topic_combine_score"])
          let score = result["_source"]["chapter_topic_combine_score"]          
           chapter_score = score.toFixed(2);

          } else {
            final[k] = {};
            // topic = result["_source"]["topic_name"]
            final[k].topic = result["_source"]["topic_name"];

          if ((result["_source"]["percentage"]).toFixed(2) >=90 ){
            final[k].grade= 'A+ ';
        }else if((result["_source"]["percentage"]).toFixed(2) >=80 ){
            final[k].grade= 'A ';
        }else if((result["_source"]["percentage"]).toFixed(2) >=70){
            final[k].grade= 'B+ ';
        }else if((result["_source"]["percentage"]).toFixed(2) >=60){
            final[k].grade= 'B ';
        }else if((result["_source"]["percentage"]).toFixed(2) >=50){
            final[k].grade= 'C ';
        }else if((result["_source"]["percentage"]).toFixed(2) >=40){
            final[k].grade= 'D ';
        }
        // a.push(grade)
        final[k].tpq = (result["_source"]["avg_time_taken_per_question"]).toFixed(2)
        final[k].percentage = (result["_source"]["percentage"]).toFixed(2)

      
      }


       }) 
      //  console.log(chapter_score);
      //  console.log(grade);
       console.log(final);
       var newArray = final.filter(value => Object.keys(value).length !== 0);
       console.log(newArray);


      //  console.log(a);

      this.setState({
        // name:name.charAt(0).toUpperCase() + name.slice(1),
        name:name,
        chapterName:chapter_name,
        chapterScore:chapter_score,
        // table:final,
        table:newArray,
        empty:false,
        queries:true,
      })


       }

    }

    else{
    let chapterUrl = `https://prod-backend.stepapp.in/api/website/report/${chapter}`;
    let apiCall = await axios.get(chapterUrl)
    console.log(apiCall.data.data);
    let apiData = apiCall.data.data
    // let name = apiCall.data.data.username
    if(apiCall.data.data === undefined){
     this.setState({
       empty:true
     })

    }else{
    let name = ''
    if ((apiData.first_name!='' && apiData.first_name!=null) && (apiData.last_name!='' && apiData.last_name!=null)) {
      name = apiData.first_name + " " + apiData.last_name;
  } else if (apiData.first_name!='' && apiData.first_name!=null) {
      name = apiData.first_name;
  } else if (apiData.username!='' && apiData.username!=null) {
      name = apiData.username;
  }
    console.log(name);
    let chapter_name = apiData.chapter_name
    let chapter_score = parseInt(apiData.chapter_topic_combine_score).toFixed(2)
    let topics = apiData.topics

    this.setState({
      // name:name.charAt(0).toUpperCase() + name.slice(1),
      name:name,
      chapterName:chapter_name,
      chapterScore:chapter_score,
      topics:topics,
      empty:false
    })
  }
}

  }
  onClick = () => {
    const input = document.getElementById('certificate_box');
    // let width = document.getElementById('certificate_box').style.width ='100%';
    const options = { background: 'white', allowTaint: true };
    console.log(input);

    
    // html2canvas(document.getElementById("certificate_box")).then(canvas => {
    //   var data = canvas.toDataURL();
    //   var pdfExportSetting = {
    //     content: [
    //       {
    //         image: data,
    //         width: 500
    //       }
    //     ]
    //   };
    //   pdfMake.createPdf(pdfExportSetting).download("test_file.pdf");
    // });

   

    // html2canvas(input,{allowTaint: true,
    //   useCORS: true})
    //     .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         // const pdf = new jsPDF('p', 'px', 'a4');
    //         const pdf = new jsPDF({
    //         orientation: 'landscape',
    //       });
    //         var width = pdf.internal.pageSize.getWidth();
    //         var height = pdf.internal.pageSize.getHeight();

    //         pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
    //         pdf.save("test.pdf");
    //     });
    // const input = document.getElementById('divToOfferInfo');


    // const pdf = new jsPDF();
    // const pdf = new jsPDF('p', 'px', 'a4');
    // var pdf = new jsPDF("p", "mm", "a4");

    // here is domtoimage-------------------------
    
    // const pdf = new jsPDF({
    //   orientation: 'landscape',
    // });
    // if (pdf) {
      domtoimage.toPng(input,options)
        .then(imgData => {
          console.log(imgData);
          
          // var width = pdf.internal.pageSize.getWidth();
          //         var height = pdf.internal.pageSize.getHeight();
          // const pdf = new jsPDF('p', 'mm', 'a4');
          const pdf = new jsPDF({
          orientation: 'landscape',
        });

          const imgProps= pdf.getImageProperties(imgData);
          // const pdfWidth = pdf.internal.pageSize.getWidth();
          // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          var width = pdf.internal.pageSize.getWidth();
          var height = pdf.internal.pageSize.getHeight();
         console.log(width);

          // pdf.addImage(imgData, 'JPEG', 0, 0,  pdfWidth, pdfHeight);
          pdf.addImage(imgData, 'JPEG', 0, 0, width , height);
          // pdf.addImage(imgData, 'PNG', -50, 20);
          pdf.save('download.pdf');
          

        });


    // }
  }
    
    render() {

      
      let { topics } = this.state
      console.log(topics);
      let data = topics.map((row,i) => ({
        topic: row.topic_name,
        grade: row.level_grade,
        tpq:row.avg_time_taken_per_question,
        percentage: `${parseInt(row.percentage).toFixed(2)}%`,
        level:++i
        // TenantUrl: row.TenantDomainUrl
      }))

      let { table } = this.state 
   
      //  let data1 = table.map((row,i) => ({ 
      //   level:++i,
      //   topic:row["_source"]["topic_name"],
      //   tpq:(row["_source"]["avg_time_taken_per_question"]).toFixed(2),
      //   percentage: `${parseInt(row["_source"]["percentage"]).toFixed(2)}%`,
      //   grade:this.state.grade

      // }))

         let data1 = table.map((row,i) => ({ 
        level:++i,
        topic:row.topic,
        tpq:row.tpq,
        percentage: `${row.percentage}%`,
        grade:row.grade

      }))

     

      
      
        return ( this.state.empty === true ?  <SiteLayout>     <Empty  className="top_margin_comn" 
    
        description={
          <span>
           No Data Found.
          </span>
        }  />   </SiteLayout> : <div>
            <SiteLayout>   
                <div className="comn_bkgimg top_margin_comn table_sec"> 
                    <div className="container">
                        <h3>Student Performance Report</h3>
                        <div className="table_respons">
                            <Table columns={columns} dataSource={this.state.queries === true ? data1 :data} />
                        </div> 
                        <div className="certificate_main" id="certificate_main" style={{width:"70%",margin:"auto"}}  >
                        <div className="certificate_box" id="certificate_box"  >
                            <img src="../img/certificate.jpg" className="img-responsive certificate_img" />
                            <div className="certificate-body">
                                <div className="cert1"><i>This is to certify that</i></div>
                                {/* <div className="cert2 cer-name" id="username"><b>SAI SANDEEP BODDEPALLI</b></div> */}
                                <div className="cert2 cer-name" id="username" style={{ textTransform: "capitalize"}}><b>{this.state.name}</b></div>

                                <div className="cer-chapter">
                                    <span className="cert4" id="mainline"><i>has completed the chapter</i></span>
                                    {/* <span className="cert2" id="chapter_name"><b>Chemical Kinetics</b></span> */}
                                      <span className="cert2" id="chapter_name"><b>{this.state.chapterName}</b></span>

                                </div>

                                {/* <div className="cert3">with an overall Knowledge wealth of &nbsp;<b id="percentage"><b>60.53</b></b><b>%</b></div> */}
                                    <div className="cert3">with an overall Knowledge wealth of &nbsp;<b id="percentage"><b>{this.state.chapterScore}</b></b><b>%</b></div>

                                <h3 className="student">Student Talent Enhancement Program</h3>
                                <span className="power">Powered by</span>
                                <img src="../img/logo.png" className="img-style" alt="icon" />
                            </div> 
                        </div>
                        </div>  

                        <div class="dwnld_btn btn btn-info btn-pill" id="certificate" onClick={this.onClick}>Download PDF</div>
                    </div> 
                </div> 
            </SiteLayout>
            </div>
        


        ) 
    }
}


